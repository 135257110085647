@import '../theme.scss';

.sidebar {
    position: fixed;
    left: 0;
    top: 66px;
    width: 225px;
    background: $bg-color;
    height: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
    z-index: 2;

    .title {
        font-weight: lighter;
        text-transform: uppercase;
        font-size: 13px;
        margin-left: 1rem;
    }

    .list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .link {
            padding: 10px 0 10px 1rem;
            text-decoration: none;
            color:  #212121;
            transition: .3s;
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;

            &.logout {
                background-color: rgba(81, 102, 116, 0.308);
            }

            &:hover {
                background-color: #887d7d1a;
            }

            &.active {
                span {
                    color: #2196f3
                }
            }

            .material-icons {
                padding-right: 1.2rem;
                font-size: 18px;
            }
        }
    }
}

@include mobileBreakpoint {
    .sidebar {
        display: none;

        &.show {
            display: block;
            animation: width .3s;
        }
    }
}