.discounts {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
    box-sizing: border-box;

    h4 {
        background-color: #f5f5f5;
        padding: 1.5rem;
        font-weight: lighter;
    }

    .add-discount {
        margin-bottom: 2rem;
        margin-left: 1rem;

        button {
            text-transform: uppercase;
            outline: 0;
            background: #3ab895;
            width: 25%;
            border-radius: 8px;
            padding: 9px;
            color: #fff;
            font-size: 12px;
            transition: all .3 ease;
            cursor: pointer;
            width: 20rem;
        }
    }

    .table-block {
        .table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            tr th {
                    display: table-cell;
                    color: #212121;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    line-height: 1.42857143;
                    padding: 16px;
                    vertical-align: middle;
                    cursor: pointer;
                    border-bottom: 2px solid #ddd;
                    text-align: left;
            }

            tr td {
                padding: 16px;
                vertical-align: middle;
                border-top: 1px solid #ddd;
                font-size: 14px;
                color: #878787;
            }
        }

        .material-icons {
            margin-left: 8px;
            &.danger {
                color: red;
            }
    
            &.success {
                color: green;
            }

        }

        
    }
}