@import '../../component/theme.scss';


.login {
    position: relative;
    height: 100vh;
    background: white; /* fallback for old browsers */
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .form-blocks {
        padding: 8% 0 0;
        margin: auto;

        form {
            position: relative;
            z-index: 1;
            background: #ffffff;
            max-width: 360px;
            margin: 0 auto 100px;
            padding: 45px;
            text-align: center;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

            .form-group {
                
                .form-input {
                    font-family: 'Roboto', sans-serif;
                    outline: 0;
                    background: #f2f2f2;
                    width: 100%;
                    border: 0;
                    margin: 0 0 15px;
                    padding: 15px;
                    box-sizing: border-box;
                    font-size: 14px;
                }
            }

            .btn-submit {
                font-family: 'Roboto', sans-serif;
                text-transform: uppercase;
                outline: 0;
                background: #3ab895;
                width: 100%;
                border: 0;
                padding: 15px;
                color: #ffffff;
                font-size: 14px;
                cursor: pointer;
                margin-top: 1rem;
            }
        }
    }

    .logo {
        height: 48px;
        position: absolute;
        top: 12px;
        left: 12px;
    }

    @include mobileBreakpoint {
        .form-blocks {
            padding: 15rem 0;
        }
    }
    
}