.notificationManager {
    top: 20px;
    right: 40px;
    position: fixed;
    z-index: 10;
    width: 300px;
    pointer-events: none;

    .notification {
        color: #fff;
        border-radius: 2px;
        margin: 0;
        font-family: 'Roboto', sans-serif;
        display: block;
        width: 100%;
        padding: 10px;
        line-height: 17px;
        position: relative;
        pointer-events: all;
        margin-bottom: 1em;

        &.bounceOut {
            animation-duration: 0.75s;
            animation-name: bounceOut;
        }

        &.bounceIn {
            animation-duration: 0.75s;
            animation-name: bounceIn;
        }

        p {
            font-weight: lighter;
            color: black;
        }

        .title {
            font-size: 16px;
            margin: 0 0 7px;
        }

        .msg {
            margin: 0;
            font-size: 12px;
        }

        .closeNotif {
            position: absolute;
            top: 3px;
            right: 7px;
            font-size: 14px;
            cursor: pointer;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}


@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
