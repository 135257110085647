@import '../../theme.scss';

.card {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
    padding: 0.3rem 1.3rem 0.3rem 1.3rem;
    justify-content: space-between;
    margin: 1rem 0;

    p {
        font-weight: lighter;
    }

    .left {
        width: 50%;
        text-align: center;
        
        .count {
            font-size: 24px;
            color: black;
            margin-bottom: 0;
        }

        .text {
            font-size: 13px;
            text-transform: uppercase;
        }
    }

    .material-icons {
        font-size: 50px;
        color: rgb(216, 206, 206);
    }
}

@include mobileBreakpoint {
    .card {
    }
}