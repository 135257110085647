@import '../theme.scss';

.header {
    background: $bg-color;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    margin-bottom: 0;
    min-height: 66px;
    padding: 0 15px 0 0;
    z-index: 9;

    .left {
        .menu {
            color: gray;
            margin-left: 1rem;
            font-size: 25px;
            display: none;
            cursor: pointer;

            @include mobileBreakpoint {
                display: inline-block;
            }
        }

        img {
            margin: 0 30px 0 15px;
            vertical-align: middle
        }

        span {
            line-height: 66px;
            font-weight: 600;
            color: #212121;
            font-size: 21px;

            @include mobileBreakpoint {
                display: none;
            }
        }
    }

    .right {
        float: right;
        padding: 0.7rem;
        img {
            height: 42px;
            width: 42px;
            vertical-align: middle;
            border-radius: 21px;
        }
    }
}