@import '../../component/theme.scss';

.home {
    display: flex;
    justify-content: center;
    .chart-block{
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
        margin: 1rem 0;
        width: 75%;
        padding-bottom: 1rem;

        .title {
            display: flex;
            justify-content: space-between;
            background: #f5f5f5;
            padding: 0.3rem 1rem 0.3rem 1rem;
            align-items: center;

            h4 {
                font-weight: lighter;
            }
            
            .total {
                display: flex;
                align-items: center;

                p {
                    margin-right: 5px;
                }
            }
        }
    }

    .card-block {
        margin-left: 2rem;
        width: 25%;
    }
}

@include mobileBreakpoint {
    .home {
        display: block;
        
        .chart-block {
            width: 100%;
        }

        .card-block {
            width: 80%
        }
    }
}