$bg-color: #f2f2f2;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-infinite: 10000px;




@mixin breakpoint($min, $max) {
    @media only screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
  
@mixin mobileBreakpoint {
    @include breakpoint($breakpoint-xs, $breakpoint-sm) {
      @content;
    }
}
  
@mixin tabletBreakpoint {
    @include breakpoint($breakpoint-sm, $breakpoint-lg) {
      @content;
    }
}
  
@mixin desktopBreakpoint {
    @include breakpoint($breakpoint-lg, $breakpoint-infinite) {
      @content;
    }
}

.panel-default {
  background: #fff;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0;
}

.panel-default .panel-heading {
  border: none;
  color: inherit;
  border-radius: 0;
  margin: -15px -15px 0;
  padding: 20px 15px;
  position: relative;
}

.panel-default .panel-heading h6 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #212121;
}

.panel-default .panel-heading .right {
  position: absolute;
  right: 15px;
  top: 20px;
  font-size: 16px;
  line-height: 26px;
}

.panel-default .panel-body {
  padding-left: 0;
  padding-right: 0;
}
