@import '../../../component/theme.scss';

.allRequests {
    .input-search {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 5px;
        outline: none;
        background-color: #f5f5f5; 
        padding-left: 15px;
      
    }
    
    .table-responsive {
        min-height: 0.01%;
        overflow-x: auto;
    }
    
    .table {
        font-size: 15px;
        color: gray;
    }
    
    .table > thead > tr > th {
        color: #212121;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.42857143;
        padding: 16px;
        vertical-align: middle;
    }
    
    .table > thead > tr > th.sortable {
        cursor: pointer;
    }
    
    .table > thead > tr > th.sortable:hover {
        text-decoration: underline;
    }
    
    .table > thead > tr > th i {
        padding-left: 8px;
        font-size: 12px;
    }
    
    .table > tbody > tr > td {
        padding: 16px;
        vertical-align: middle;
    }

    .view-ordo {
        color: rgb(73, 73, 136);
        cursor: pointer;
        margin: 5px 0;
        
        &:hover {
            text-decoration: underline;
        }
    }

    .material-icons {
        cursor: pointer;
        margin-left: 10px;
    }
}