@import '../../component/theme.scss';


.preinscri {
    position: relative;
    height: 100vh;
    background: #ffffff; /* fallback for old browsers */
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    display: flex;
    -moz-osx-font-smoothing: grayscale;

    .form-blocks {
        padding: 2% 0 0;
        margin: auto;

        form {
            position: relative;
            z-index: 1;
            background: #ffffff;
            max-width: 660px;
            margin: 0 auto 100px;
            text-align: center;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

            .input-block {
                background-image: url('../../../public/assets/images/bg-image.png');
                background-position: center;
                background-size: 100% 70em;
                padding: 20px 25px 5px;
            }

            .check-block {
                display: flex;
                gap: 15px;
                align-items:flex-start;
                text-align: left;
                font-size: 13px;

                input {
                    margin-top: 2px;
                }
            }

            .__flex {
                display: flex;
                justify-content: space-between;

                .form-group {
                    width: 48%;
                }
            }

            .form-group {
                .form-input {
                    font-family: 'Roboto', sans-serif;
                    outline: 0;
                    background: #ffffff;
                    width: 100%;
                    margin: 0 0 15px;
                    padding: 8px 8px 8px 25px;
                    font-size: 14px;
                    border: none;
                    border-radius: 30px;
                    box-shadow: 0px 0px 0px 0.3px rgba(74, 74, 74, 0.381);
                    &:focus {
                        border: 1px solid rgba(8, 121, 121, 0.99);
                        box-shadow: none;
                    }
                }

                .btn-group {
                    display: flex;
                    gap: 15px;
                    justify-content: center;

                    button {
                        border-radius: 5px;
                        padding: 6px 15px 6px 15px;
                        border: 1px solid rgba(54, 163, 163, 1);
                        color: rgba(54, 163, 163, 1);
                        background-color: white;

                        &.active, &:hover {
                            background-color: rgba(54, 163, 163, 1);
                            color: white;
                        }
                    }

                    &.input {
                        width: 50%;
                    }
                }

                &.quest {
                    p {
                        font-size: 13px;
                        font-weight: 600;
                        color: rgba(39, 92, 91, 0.9);
                        vertical-align: middle;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .btn-submit {
                font-family: 'Roboto', sans-serif;
                text-transform: uppercase;
                outline: 0;
                width: 100%;
                background-color: rgba(54, 163, 163, 1);
                padding: 15px;
                color: white;
                font-size: 14px;
                cursor: pointer;
                margin-top: 1rem;
                border-radius: 5px;
                border: none;
                font-weight: 600;
            }
        }
    }

    .logo {
        height: 48px;
        position: absolute;
        top: 12px;
        left: 12px;
    }

    @include mobileBreakpoint {
        .form-blocks {
            padding: 15rem 0;

            form {
                .__flex {
                    display: block;
                    .form-group {
                        width: 100%;
                    }
                }

                .input-block {
                    background-size: 30em 110em;
                }

                .form-group {
                    &.quest {
                        display: block;
                    }

                    .btn-group.input {
                        width: 100%;
                    }
                }
    
            }
        }
    }


    
    
}