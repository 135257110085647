@import '../theme.scss';

.layout {
    .content {
        position: relative;
        top: 70px;
        left: 250px;
        width: 78%;

        @include mobileBreakpoint {
            left: 0;
            top: 55px;
            width: 100%;
        }
    }
}